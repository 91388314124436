import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Auth0ProviderCustom } from "./auth0-provider-custom";
import { AuthorizationProvider } from "./providers/authorization-provider";
import { FlowbiteProvider } from "./providers/flowbite-provider";
import { useEffect } from "react";
import {
	BrowserRouter,
	Route,
	Routes,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import React from "react";

export function App() {
	const router = createBrowserRouter(routes);
	const queryClient = new QueryClient();

	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration(),
			// Or, if you are using react router, use the appropriate integration
			// See docs for support for different versions of react router
			// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		// Sentry recommends adjusting this value in production, or using tracesSampler for finer control
		tracesSampleRate: Number.parseFloat(
			process.env.SENTRY_TRACES_SAMPLE_RATE || "1.0",
		),
	});

	return (
		<Auth0ProviderCustom>
			<QueryClientProvider client={queryClient}>
				<AuthorizationProvider>
					<FlowbiteProvider>
						<RouterProvider router={router} />
					</FlowbiteProvider>
					{/* <ReactQueryDevtools initialIsOpen={false} /> */}
				</AuthorizationProvider>
			</QueryClientProvider>
		</Auth0ProviderCustom>
	);
}
