import { createContext, useContext, useState } from "react";
import { AxiosBase, indexUrl, createUrl } from "../shared/use-rest-query";
import { useAuth0 } from "@auth0/auth0-react";

const defaultContext = {
	user: null,
	practice: null,
	// setUser: (newUser) => {},
	// setPractice: (newPractice) => {},
	authorize: () => {},
	isAuthorized: false,
};

export const AuthorizationContext = createContext(defaultContext);

export const useAuthorization = () => useContext(AuthorizationContext);

export const AuthorizationProvider = ({ children }) => {
	// const [authorizationContext, setState] = useState(defaultContext);
	const [user, setUser] = useState(null);
	const [practice, setPractice] = useState(null);
	const [isAuthorized, setIsAuthorized] = useState(false);
	const { getAccessTokenSilently } = useAuth0();

	const getAuthorizedPractices = async () => {
		const accessToken = await getAccessTokenSilently();
		const authorizedPracticesResponse = await AxiosBase.get(
			indexUrl("authorizations/practices"),
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			},
		);
		const authorizedPractices = authorizedPracticesResponse?.data?.data;
		return authorizedPractices;
	};

	const authorize = async () => {
		const accessToken = await getAccessTokenSilently();
		const authHeaders = { Authorization: `Bearer ${accessToken}` };
		const authorizedPractices = await getAuthorizedPractices();
		const practiceId = process.env.PRACTICE_ID || authorizedPractices?.[0]?.id;
		const authorizationResponse = await AxiosBase.post(
			createUrl("authorizations"),
			{ id: practiceId },
			{ headers: authHeaders },
		);
		setPractice(authorizationResponse?.data?.data);
		const currentUserResponse = await AxiosBase.get(
			indexUrl("authorizations"),
			{ headers: authHeaders },
		);
		setUser(currentUserResponse?.data?.data);
		setIsAuthorized(true);
	};

	return (
		<AuthorizationContext.Provider
			value={{ user, practice, authorize, isAuthorized }}
		>
			{children}
		</AuthorizationContext.Provider>
	);
};
