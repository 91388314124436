import { Outlet, useLocation } from "react-router-dom";
import { Layout } from "./layout";
import { useAuthorization } from "./providers/authorization-provider";
import { useAuth0 } from "@auth0/auth0-react";

export const AuthenticatedLayout = ({ children }) => {
	const location = useLocation();

	const { authorize, isAuthorized } = useAuthorization();
	const { loginWithRedirect, isLoading, error, isAuthenticated } = useAuth0();

	// Do not attempt to automatically log back in if the user is
	// in the process of logging out or on the login page
	if (!["/logout", "/login"].includes(location.pathname)) {
		if (!isLoading && !error && !isAuthenticated) {
			// If user is not authenticated, redirect to login page
			loginWithRedirect({ appState: { returnTo: location.pathname } });
		}
		// If user is not authorized, authorize them
		if (isAuthenticated && !isAuthorized) {
			authorize();
		}
	}

	return (
		<Layout>
			<Outlet />
		</Layout>
	);
};
