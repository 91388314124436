import {
	Link,
	Navigate,
	Outlet,
	useLocation,
	useMatch,
	useNavigate,
	useResolvedPath,
} from "react-router-dom";
import React, { createContext, useContext, useEffect, useState } from "react";

import {
	Calendar,
	ChartBar,
	ChartDonut,
	ChartLine,
	ChartPie,
	CheckCircle,
	ClipboardText,
	Gear,
	Money,
	Phone,
	PhoneCall,
	PhoneOutgoing,
	PresentationChart,
	UserCirclePlus,
} from "@phosphor-icons/react";
import { AuthorizationContext } from "./providers/authorization-provider";
import { Breadcrumbs } from "./components/breadcrumbs";
import { FooterCta, ReferPatient } from "./components/footer-cta";
import { classNames } from "./shared/classnames";
import { ReferPatientModal } from "./components/refer-patient-modal";
import { Modal } from "./components/modal";
import { Avatar } from "./components/avatar";
import { useAuth0 } from "@auth0/auth0-react";
import { event } from "./shared/events";

export const Layout = ({ children }) => {
	const logoFullColor = new URL(
		"./assets/logo-full-color.svg",
		import.meta.url,
	).toString();
	const utah = new URL("./assets/utah.png", import.meta.url).toString();

	const useMatchesPath = (path) =>
		useMatch({
			path: useResolvedPath(path).pathname,
		});

	const studiesIsActivePaths = ["/", "/studies"];
	const studiesIsActive = studiesIsActivePaths
		.map((path) => useMatchesPath(path))
		.some((match) => match !== null);

	const [showSidebar, setShowSidebar] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(<div />);

	const location = useLocation();

	// Close the sidebar when the location changes
	useEffect(() => {
		if (!location) return;
		setShowSidebar(false);
		setShowUserMenu(false);
	}, [location]);

	const [showUserMenu, setShowUserMenu] = useState(false);
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();
	const { user, practice } = useContext(AuthorizationContext);
	// TODO: Look this up from /practices API
	const practicePhone = "(303) 228-8616,1"; // RMG

	const handleNavClick = async (navElement) => {
		event({
			eventName: "Click",
			meta: {
				element: `Nav${navElement?.label}`,
			},
			getAccessTokenSilently,
		});
	};

	const handleUserMenuClick = async (profileElement) => {
		event({
			eventName: "Click",
			meta: {
				element: profileElement,
			},
			getAccessTokenSilently,
		});
	};

	// TODO: move this to routes, with a "show in sidebar" config
	const navConfig = [
		{
			label: "Studies",
			path: "/studies",
			icon: <ClipboardText weight="bold" size="20" />,
		},
		{
			label: "Tasks",
			path: "/tasks",
			icon: <CheckCircle weight="bold" size="20" />,
			hidden: true,
		},
		{
			label: "Finances",
			path: "/finances",
			hidden: true,
			icon: <Money weight="bold" size="20" />,
		},
		{
			label: "Metrics",
			path: "/metrics",
			icon: <ChartLine weight="bold" size="20" />,
		},
		// {
		// 	label: "Calendar",
		// 	path: "/calendar",
		// 	icon: <Calendar weight="bold" size="20" />,
		// },
	];

	const navElements = navConfig.map((element) => ({
		...element,
		match: useMatch(element.path),
	}));

	if (!isAuthenticated) return <></>;

	return (
		<div className="h-full antialiased bg-gray-50 dark:bg-gray-900">
			{/* Modal */}
			{/* <div className={`${showModal ? "block" : "hidden"}`}> */}
			<Modal setShowModal={setShowModal} showModal={showModal}>
				<ReferPatientModal studies={[]} />
			</Modal>
			{/* </div> */}
			<nav className="bg-white border-b border-gray-200 px-1 md:px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
				<div className="flex flex-wrap justify-between items-center">
					<div className="flex justify-start items-center">
						<button
							onClick={() => {
								setShowSidebar(!showSidebar);
								setShowUserMenu(false);
							}}
							type="button"
							data-drawer-target="drawer-navigation"
							data-drawer-toggle="drawer-navigation"
							aria-controls="drawer-navigation"
							className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
						>
							<svg
								aria-hidden="true"
								className="w-6 h-6"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
									clipRule="evenodd"
								/>
							</svg>
							<svg
								aria-hidden="true"
								className="hidden w-6 h-6"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"
								/>
							</svg>
							<span className="sr-only">Toggle sidebar</span>
						</button>
						<a href="/" className="flex items-center justify-between mr-4">
							<img alt="logo" src={logoFullColor} className="h-8" />
							{/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
									Topography Health
								</span> */}
						</a>
						{/* <form action="#" method="GET" className="hidden md:block md:pl-2">
								<label for="topbar-search" className="sr-only">
									Search
								</label>
								<div className="relative md:w-64 md:w-96">
									<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
										<svg
											className="w-5 h-5 text-gray-500 dark:text-gray-400"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<title>"Search icon</title>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
											/>
										</svg>
									</div>
									<input
										type="text"
										name="email"
										id="topbar-search"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
										placeholder="Search"
									/>
								</div>
							</form> */}
					</div>
					<div className="flex items-center lg:order-2">
						{/* Dropdown menu */}
						<div
							className="hidden overflow-hidden z-40 my-4 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
							id="apps-dropdown"
						>
							<div className="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-600 dark:text-gray-300">
								Apps
							</div>
							<div className="grid grid-cols-3 gap-4 p-4">
								<a
									href="#"
									className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
								>
									<svg
										aria-hidden="true"
										className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
											clipRule="evenodd"
										/>
									</svg>
									<div className="text-sm text-gray-900 dark:text-white">
										Sales
									</div>
								</a>
								<a
									href="#"
									className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
								>
									<svg
										aria-hidden="true"
										className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
									</svg>
									<div className="text-sm text-gray-900 dark:text-white">
										Users
									</div>
								</a>
								<a
									href="#"
									className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
								>
									<svg
										aria-hidden="true"
										className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z"
											clipRule="evenodd"
										/>
									</svg>
									<div className="text-sm text-gray-900 dark:text-white">
										Inbox
									</div>
								</a>
								<a
									href="#"
									className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
								>
									<svg
										aria-hidden="true"
										className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
											clipRule="evenodd"
										/>
									</svg>
									<div className="text-sm text-gray-900 dark:text-white">
										Profile
									</div>
								</a>
								<a
									href="#"
									className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
								>
									<svg
										aria-hidden="true"
										className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
											clipRule="evenodd"
										/>
									</svg>
									<div className="text-sm text-gray-900 dark:text-white">
										Settings
									</div>
								</a>
								<a
									href="#"
									className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
								>
									<svg
										aria-hidden="true"
										className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
										<path
											fillRule="evenodd"
											d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
											clipRule="evenodd"
										/>
									</svg>
									<div className="text-sm text-gray-900 dark:text-white">
										Products
									</div>
								</a>
								<a
									href="#"
									className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
								>
									<svg
										aria-hidden="true"
										className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
										<path
											fillRule="evenodd"
											d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
											clipRule="evenodd"
										/>
									</svg>
									<div className="text-sm text-gray-900 dark:text-white">
										Pricing
									</div>
								</a>
								<a
									href="#"
									className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
								>
									<svg
										aria-hidden="true"
										className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm2.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.207.293a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414zM12.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
											clipRule="evenodd"
										/>
									</svg>
									<div className="text-sm text-gray-900 dark:text-white">
										Billing
									</div>
								</a>
								<a
									href="#"
									className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
								>
									<svg
										aria-hidden="true"
										className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
										/>
									</svg>
									<div className="text-sm text-gray-900 dark:text-white">
										Logout
									</div>
								</a>
							</div>
						</div>
						<Link
							to={`tel:${practicePhone}`}
							className="hidden inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
						>
							<Phone />
							<div className="pl-2">Call Topo</div>
						</Link>
						<button
							onClick={() => {
								setShowUserMenu(!showUserMenu);
								setShowSidebar(false);
								handleUserMenuClick("UserMenu");
							}}
							type="button"
							className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
							id="user-menu-button"
							aria-expanded="false"
							data-dropdown-toggle="dropdown"
						>
							<span className="sr-only">Open user menu</span>
							<Avatar name={user?.name} />
						</button>{" "}
						{/* Dropdown menu */}
						<div
							className={`${
								showUserMenu ? "absolute" : "hidden"
							} right-4 top-10 z-40 my-4 w-56 text-base list-none bg-white divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl`}
							id="dropdown"
						>
							<div className="py-3 px-4  hover:cursor-default">
								<span className="block text-sm font-semibold text-gray-900 truncate dark:text-white">
									{user?.name}
								</span>
								<span className="block text-sm text-gray-900 dark:text-white">
									{practice?.practice_name}
								</span>
							</div>
							<div>
								<ul
									className="py-1 text-gray-700 dark:text-gray-300"
									aria-labelledby="dropdown"
								>
									<li>
										<Link
											to="legal/terms-of-service"
											className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
											onClick={() => {
												handleUserMenuClick("TermsOfService");
											}}
										>
											Terms of Service
										</Link>
									</li>
									<li>
										<Link
											to="legal/privacy-policy"
											className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
											onClick={() => {
												handleUserMenuClick("PrivacyPolicy");
											}}
										>
											Privacy Policy
										</Link>
									</li>
								</ul>
							</div>
							<ul
								className="py-1 text-gray-700 dark:text-gray-300"
								aria-labelledby="dropdown"
							>
								<li>
									<Link
										to="/logout"
										className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
										onClick={() => {
											handleUserMenuClick("SignOut");
										}}
									>
										Sign out
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>

			{/* Sidebar */}

			<aside
				className={classNames(
					{ "-translate-x-full": !showSidebar },
					{ "translate-x-0": showSidebar },
					"md:translate-x-0 fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform  bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700",
				)}
				aria-label="Sidenav"
				id="drawer-navigation"
			>
				<div className="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800">
					<ul className="space-y-2">
						{navElements.map((element) => (
							<li className="hover:cursor-pointer" key={element.label}>
								<Link
									to={element.path}
									className={classNames(
										{ hidden: element?.hidden },
										{ "bg-gray-50": element?.match },
										"flex hover:cursor-pointer items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group",
									)}
									onClick={() => handleNavClick(element)}
								>
									{element.icon}
									<span className="ml-3 hover:cursor-pointer">
										{element.label}
									</span>
								</Link>
							</li>
						))}
					</ul>
					<ul className="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
						<li>
							<a
								href={`tel:${practicePhone}`}
								className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group"
								onClick={() => handleUserMenuClick("NavCallTopo")}
							>
								<PhoneCall weight="bold" size="20" />
								<span className="ml-3">Call Topo</span>
							</a>
						</li>
						<li>
							<div className="flex flex-1 items-center text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
								<button
									type="button"
									className="flex items-center  p-2 flex-1"
									onClick={() => {
										setShowModal(true);
										handleUserMenuClick("NavReferPatient");
									}}
								>
									<UserCirclePlus weight="bold" size="20" />
									<span className="ml-3">Refer a Patient</span>
								</button>
							</div>
						</li>
					</ul>
				</div>
				<div className="hidden absolute bottom-0 left-0 p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20">
					{/* Logout */}
					<div className="text-xs ml-2 align-top font-light">
						Beta version v1.0
					</div>
				</div>
			</aside>

			<main className="md:ml-64 h-full pt-16">
				<Breadcrumbs />
				{children}
				<FooterCta setShowModal={setShowModal} practicePhone={practicePhone} />
			</main>
		</div>
	);
};
